<template>
    <div>
        <ek-dialog
            btnText="بحث جديد"
            :title="'اضافة بحث'"
            @ok="submit"
            ref="dialog"
            @close="reset"
        >
            <template #body>
                <validation-observer ref="chapterForm">
                    <b-form>
                        <ek-input-text
                            name="اسم البحث"
                            label="اسم البحث"
                            v-model="chapterDto.name"
                            placeholder="ادخل اسم البحث"
                            :rules="[
                                { type: 'required', message: 'اسم مطلوب' },
                            ]"
                        ></ek-input-text>
                        <ek-input-select
                            :options="unitsOptions"
                            v-model="chapterDto.unitId"
                            label="اختر وحدة"
                            name="اختر وحدة"
                            :rules="[
                                { type: 'required', message: 'الوحدة مطلوبه' },
                            ]"
                        ></ek-input-select>
                        <ek-input-select
                            v-model="chapterDto.teacherId"
                            :options="teachersList"
                            label="اختر استاذ"
                            name="اختر استاذ"
                            :rules="[
                                { type: 'required', message: 'الاستاذ مطلوب' },
                            ]"
                        ></ek-input-select>
                        <ek-input-text
                            name="سعر البحث"
                            label="سعر البحث"
                            type="number"
                            v-model="chapterDto.price"
                            placeholder="ادخل سعر البحث"
                            :rules="[
                                { type: 'required', message: 'السعر مطلوب' },
                            ]"
                        ></ek-input-text>
                        <ek-input-text
                            name="ترتيب البحث"
                            type="number"
                            label="ترتيب البحث"
                            v-model="chapterDto.order"
                            placeholder="ادخل ترتيب البحث"
                            :rules="[
                                { type: 'required', message: 'الترتيب مطلوب' },
                            ]"
                        ></ek-input-text>
                        <b-form-group label="حالة البحث">
                            <b-form-radio-group
                                id="radio-isFree"
                                :options="options"
                                v-model="chapterDto.isFree"
                                name="حالةالبحث"
                            ></b-form-radio-group>
                        </b-form-group>
                    </b-form>
                </validation-observer>
            </template>
        </ek-dialog>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
    data() {
        return {
            options: [
                { text: "مجاني", value: true },
                { text: "غير مجاني", value: false },
            ],
        };
    },
    computed: {
        ...mapState({
            chapterDto: (state) => state.chapter.chapterDto,
            unitsOptions: (state) => state.units.unitsOptions,
            teachersList: ({ teachers }) => teachers.teachers,
        }),
    },
    methods: {
        ...mapActions(["addChapter", "getTeachersList"]),
        ...mapMutations(["RESET_CHAPTER_DTO"]),

        submit() {
            this.$refs["chapterForm"].validate().then((suc) => {
                if (suc) {
                    this.addChapter(this.chapterDto);
                    this.$refs.dialog.close();
                    this.$store.commit("RESET_CHAPTER_DTO");
                }
            });
        },

        reset() {
            this.$refs.chapterForm.reset();
            this.RESET_CHAPTER_DTO();
            this.getTeachersList();
        },
    },
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
